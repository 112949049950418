// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCCIc3JYpEvCKm4DYbH_z_1NcKZZSgNPjY",
    authDomain: "and-ath-website.firebaseapp.com",
    databaseURL: "https://and-ath-website.firebaseio.com",
    projectId: "and-ath-website",
    storageBucket: "and-ath-website.appspot.com",
    messagingSenderId: "561685784928",
    appId: "1:561685784928:web:dc7905e09f7793ec959605",
    measurementId: "G-NZ2MMKBJNC"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
