<app-section>
  <h3 class="bold-heading">OFFICERS</h3>
  <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="space-around start">
    <mat-card class="item" fxFlex.xs="100">
      <mat-card-header fxLayout="column" fxLayoutGap="32px">
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="">
      <mat-card-content>
        <p>
          The Shiba Inu is the smalest of the six original and distinct spitz breeds of dog from Japan.
          A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
          bred for hunting.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Facebook</button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="item" fxFlex.xs="100">
      <mat-card-header fxLayout="column" fxLayoutGap="32px">
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="">
      <mat-card-content>
        <p>
          The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
          A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
          bred for hunting.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Twitter</button>
        <button mat-button>Facebook</button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="item" fxFlex.xs="100">
      <mat-card-header fxLayout="column" fxLayoutGap="32px">
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="">
      <mat-card-content>
        <p>
          The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
          A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
          bred for hunting.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Facebook</button>
      </mat-card-actions>
    </mat-card>
  </div>
</app-section>