<app-section color="mat-blue">
  <h2 id="recruit">RECRUIT</h2>
</app-section>

<app-section>
  <h1>韓国の大手物流企業が日本に進出するため<br>
    各拠点でのオープニングスタッフ大募集！　（韓国語の堪能な方と働くことができる環境！）</h1>

  <h3>業務内容</h3>
  <p>
    ・物流拠点での端末やシステムのサポート業務全般<br>
    ・コールセンター業務（お客様からのヘルプ対応）<br>
    ・キッティング作業（初期設定、セッティングなどの初期作業）
  </p>

  <h3>応募資格</h3>
  <p>
    1.テクニカルサポート経験者（数ヶ月でも可！）<br>
    ・コールセンター<br>
    ・キッティング作業 etc…<br>
    2.韓国語を勉強したい方（韓国語が話せる方は大歓迎、もちろん外国籍の方も歓迎！）<br>
    3.ITリテラシーor知見のある方（勉強したい方も可！）
  </p>

  <h3>求める人物像</h3>
  <p>
    ・新宿<br>
    ・品川<br>
    今後、都内全地域への展開を予定
  </p>

  <h3>就業時間</h3>
  <p>
    （早番）8：00～16：00　（遅番）15：00～00：00（休憩：90分）　
    ※シフトは希望に合わせて調整も可能
  </p>

  <button mat-button class="big-button" [color]="'primary'" [routerLink]="'/company/contact'">
    まずは話を聞いてみる
  </button>
</app-section>