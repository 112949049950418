<h3 class="bold-heading">プライバシーポリシー</h3>
    <div>
      <mat-card-content>
        <p>株式会社&ath は、各種サービスのご提供にあたり、お客様の個人情報をお預かりしております。 弊社は主に、人材紹介業を営む企業として個人情報を保護し、お客様に更なる信頼性と安心感をご提供できる企業として、個人情報を保護するように努めて参ります。 弊社は、個人情報に関する法令を遵守し、個人情報の適切な取り扱いを実現いたします。<br>
            1. 個人情報を取り扱う際は、事業運営ならびに従業員の雇用、人事管理上に必要な範囲でのみ取得し、利用、提供を行います。また、本人から同意を得た範囲を超えて個人情報が目的外の利用、提供がされないよう管理します。<br>
            2. 個人情報は、原則として法律に基づく命令等を除いて、特定された利用目的の達成に必要な範囲を超えた個人情報の取り扱いは行いません。また、目的外の利用を行わないよう、適切かつ必要な措置を講じます。<br>
            3. 個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、滅失、毀損及び漏洩などのリスクに対しては、合理的な安全対策を講じ、予防並びに是正を行います。<br>
            4. 個人情報の開示、訂正、削除、利用および提供の拒否要求、その他苦情、ご相談など、弊社が保有する個 人情報に関するお問合せについては、適切な本人確認をさせていただいた上で、合理的な範囲ですみやかに対応します。<br>
            5. 弊社は以下のいずれかの場合を除いて、個人情報を第三者に提供しません。<br>
            ・本人の同意がある場合<br>
            ・法令等により開示を求められた場合 <br>
            ・本人または公衆の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br>
            ・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br>
            ・国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br>
            6. 個人情報を取り扱う業務をほかの会社に委託する場合、個人情報を取得する時の承諾に基づく利用、提供、安全管理を守るように、委託先に対する適切な契約や指導・管理をします。<br>
            7. 弊社が取り扱う個人情報に関して適用される法令、国が定める指針その他規範を遵守するとともに、適切な運用が実施されるよう管理と必要な是正を行い、個人情報保護のマネジメントシステムを継続的に見直し、改善していきます。<br>
            <br>
            <個人情報の利用目的><br>
            弊社は、個人情報を以下の利用目的の達成に必要な範囲内で、利用します。 以下に定めのない目的で個人情報を利用する場合、あらかじめご本人の同意を得た上で行ないます。<br>
            ・人材紹介、IT ソリューション事業など弊社サービスを提供するため<br>
            ・仕事紹介を目的とした、スキル・経験等の確認<br>
            ・求人会社への求職者情報の提供のため<br>
            ・人事労務管理、給与・健康管理、安全管理<br>
            ・弊社サービスに関する情報及び資料送付のため 
            ・契約の締結、維持管理、契約に基づく通知、請求、支払業務のため<br>
            ・サービスに関するアンケート調査等<br>
            ・採用に関する選考、決定のため<br>
            ・キャリア形成支援を目的とした教育訓練等のため<br>
            ・本人の求めによる各種お問い合わせへの対応のため<br>
            ・統計データ作成のため<br>
            ・上記に付随するサービスの遂行のために収集し、それ以外の目的では使用しません<br>
            <br>
            <個人情報の取り扱いに関する窓口> <br>
            事業社名:株式会社&ath<br>
            所在地 :東京都千代田区平河町 1-6-4 H1O 平河町 805 号室<br>
            電話番号:03-6261-0417<br>
            E-mail :info@and-ath.com<br>
            営業時間:月~金 (土日祝日除く) 10:00 ~ 18:00<br>
            <br>
            以上 
            <br>
            株式会社&ath<br>
            代表取締役 三保将司<br>
        </p>
      </mat-card-content>
    <div>