<app-section [color]="'white'">
    <div class="scouting-title">
     <h2 class="bold-heading" [ngSwitch]="''">
        <ng-container *ngSwitchDefault>スカウティング支援サービス
        </ng-container>
    </h2>
        <p>
        アスリートがスカウトマンの目に止まるには、スカウトマンが選手を直接見ることでしか実現しない。
        <br />
        スカウティングのIT化を促進し、補完的な役割を担うため本サービスを提案致します。
        <br />
        <br />
        ※現時点では「高校生」「スポーツは野球」に限定させて頂きます。
        <br />
        ※スカウトマンへの情報提供（自己アピール）のプラットフォームとしてご利用くださいませ。
        <br />
        </p>
        <button mat-raised-button color="primary" routerLink="/company/contact">
        お問い合わせはこちら
        </button>
        </div>
    <h2 class="bold-heading" [ngSwitch]="''">
      <ng-container *ngSwitchDefault>特徴</ng-container>
    </h2>
    <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="space-around start">
      <div fxFlex="100">
        <mat-card>
          <mat-card-header>
            <mat-card-title>費用</mat-card-title>
            <mat-card-subtitle>無料</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="100">
        <mat-card>
          <mat-card-header>
            <mat-card-title>to アスリート</mat-card-title>
            <mat-card-subtitle>サービス使用方法</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              お問い合わせフォームから、以下の内容をお送りくださいませ。
            <br>
            ・氏名
            <br>
            ・メールアドレス
            <br>
            ・年齢
            <br>
            ・高校名
            <br> 
            ・高校卒業予定年
            <br>
            ・自己PR
            <br>
            ・自身の練習風景やアピール内容を収めたYoutubeの動画URL
            <br>
            <br>
            ※Youtubeは『限定公開』設定をお願い致します。
            <br>
            ※あくまでスカウトマンへの情報提供ですので、スカウトマンと選手とのやりとりを仲介するサービスではないことはご了承ください。
            <br>
            ※スカウトマンに提供する情報は、氏名・年齢・高校名・自己PR・Youtubeの動画URL、です。
            <br>
            ※スカウトマンに公開したくない情報、また情報の消去などございましたら柔軟に対応させて頂きますので、お問い合わせ内にてその旨を記載ください。（基本的には、頂いた情報は卒業年を過ぎると削除させて頂きます。）
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="100">
        <mat-card>
          <mat-card-header>
            <mat-card-title>to スカウトマン</mat-card-title>
            <mat-card-subtitle>サービス使用方法</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              お問い合わせフォームから、以下の内容をお送りくださいませ。
            <br>
            ・氏名
            <br>
            ・メールアドレス
            <br>
            ・所属団体名（または企業名）
            <br>
            ・どのような選手をスカウトしたいのか詳細
            <br>
            <br>
            ※お問い合わせフォームよりご連絡後、弊社から別途メール（info@and-ath.com）をお送り致します。
            その後、弊社に登録された選手のデータをご覧頂きます。
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </app-section>