<app-section [color]="'white'">
  <h3 class="bold-heading">SERVICES</h3>
  <div fxLayout="row wrap" fxLayoutGap="5px grid" fxLayoutAlign="space-around start">
    <div class="item" fxFlex.xs="100">
      <mat-card-title>スポーツタレント肖像の<br>サブスク事業“アストク”</mat-card-title>
      <lib-ripple-card>
          <img mat-card-image src="assets/images/luster/fistbump-over-desk.jpg" width="100%" height="100%" />
          <mat-card-content>
            <p>スポーツタレントの肖像をサブスクリプション化することで、多額の費用をかけることなく広告に活用できます。
            </p>
          </mat-card-content>
      </lib-ripple-card>
    </div>
    <div class="item" fxFlex.xs="100">
      <mat-card-title>コンサル案件/人材ソーシング</mat-card-title>
      <lib-ripple-card>
        <a routerLink="services/consulting">
          <img mat-card-image src="assets/images/luster/consultant.jpg" width="100%" height="100%" />
          <mat-card-content>
            <p>あらゆるポジションに特化した⼤⼿・上場企業の案件多数のため、
              他にはないハイクラス案件のご紹介が継続的に可能です。</p>
          </mat-card-content>
        </a>
      </lib-ripple-card>
    </div>
    <div class="item" fxFlex.xs="100">
      <mat-card-title>企業向け元ラグビー日本代表監修<br>リーダーシップ・<br>コミュニケーション研修
      </mat-card-title>
      <lib-ripple-card>
        <a href="https://r.goope.jp/andath">
          <img mat-card-image src="assets/images/luster/hands-on-desk-at-meeting.jpg" width="100%" height="100%" />
          <mat-card-content>
            <p>「ラグビーメソッドプログラム」に基づき、自分らしいマネジメントスタイル、リーダーシップスタイル、コーチングスタイルを確立する。
              <br>
            </p>
          </mat-card-content>
        </a>
      </lib-ripple-card>
    </div>
    <div class="item" fxFlex.xs="100">
      <mat-card-title>アスリートの<br>セカンドキャリア支援
      </mat-card-title>
      <lib-ripple-card>
        <a routerLink="services/support-career">
          <img mat-card-image src="assets/images/luster/athlete_secondcareer.jpg" width="193"
            height="130" />
          <mat-card-content>
            <p>トップスポーツ団体や大手・中小企業と、輝かしい実績のあるトップアスリートや独立リーグなどに所属していたアスリートとの新たな架け橋となり、企業における人材不足課題を解決します。
            </p>
          </mat-card-content>
        </a>
      </lib-ripple-card>
    </div>
    <div class="item" fxFlex.xs="100">
      <mat-card-title>若手ハイキャリア層の転職支援
      </mat-card-title>
      <lib-ripple-card>
        <a routerLink="services/support-career">
          <img mat-card-image src="assets/images/luster/businessman-in-glass-elevator-going-up.jpg" width="193"
            height="130" />
          <mat-card-content>
            <p>人材支援を行ってきた&athが保有する人材データベースを活用して、ITや経営戦略、新規事業創出に長けた若手ハイキャリア層を紹介できます。
            </p>
          </mat-card-content>
        </a>
      </lib-ripple-card>
    </div>
  </div>
</app-section>