import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesRoutingModule } from './services-routing.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../core/shared/shared.module';
import { MaterialModule } from '../core/module/material/material.module';
import { ScoutingComponent } from './scouting/scouting.component';
import { ConsultingComponent } from './consulting/consulting.component';

@NgModule({
  declarations: [
    ScoutingComponent,
    ConsultingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ServicesRoutingModule,
    MaterialModule,
    ServicesRoutingModule,
    SharedModule,
  ],
})
export class ServicesModule {}
