<app-section>
  <h2 class="bold-heading">CONTACT</h2>
  <form #form="ngForm" (submit)="
        onSubmit(
          name.value,
          email.value,
          subject.value[0],
          body.value,
          subject.value[1]
        )
      ">
    <mat-form-field fxFill>
      <mat-label [ngSwitch]="''">
        <ng-container *ngSwitchDefault>お名前</ng-container>
      </mat-label>
      <input #name matInput required ngModel name="name" />
    </mat-form-field>
    <mat-form-field fxFill>
      <mat-label [ngSwitch]="''">
        <ng-container *ngSwitchDefault>メールアドレス</ng-container>
      </mat-label>
      <input #email matInput required type="email" ngModel name="email" />
    </mat-form-field>
    <mat-form-field fxFill>
      <mat-label [ngSwitch]="''">
        <ng-container *ngSwitchDefault>件名</ng-container>
      </mat-label>
      <mat-select #subject matInput required ngModel name="subject" [ngSwitch]="''">
        <ng-container *ngSwitchDefault>
          <mat-option [value]="[
                'スカウティングサービス',
                subjectTypes.SCOUTING
              ]">スカウティングサービスに関して
          </mat-option>
          <mat-option [value]="[
              'コンサル案件/人材ソーシング',
              subjectTypes.CONSULTING
            ]">コンサル案件/人材ソーシング
          </mat-option>
          <mat-option [value]="[
                'スポ留',
                subjectTypes.SPO_RYU
              ]">スポ留に関して
          </mat-option>
          <mat-option [value]="[
                'セカンドキャリア支援',
                subjectTypes.SECOND_CAREER
              ]">セカンドキャリアに関して
          </mat-option>
          <mat-option [value]="[
                '',
                subjectTypes.SALES
              ]">弊社へのセールス
          </mat-option>
          <mat-option [value]="[
          '',
          subjectTypes.RECRUIT
        ]">採用に関して
          </mat-option>
          <mat-option [value]="[
                'その他',
                subjectTypes.INFO
              ]">その他
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFill>
      <mat-label [ngSwitch]="''">
        <ng-container *ngSwitchDefault>
          メッセージを入力してください
        </ng-container>
      </mat-label>
      <textarea #body rows="12" style="resize: none;" matInput required ngModel name="body"></textarea>
    </mat-form-field>
    <div fxLayout="row">
      <div>
        <mat-checkbox fxFill required ngModel name="agree" [ngSwitch]="''">
          <ng-container *ngSwitchDefault>
            プライバシーポリシーに同意する
          </ng-container>
        </mat-checkbox>
      </div>
      <span fxFlex="1 1 auto"></span>
      <a routerLink="/terms" [ngSwitch]="''">
        <ng-container *ngSwitchDefault>プライバシーポリシー</ng-container>
      </a>
    </div>

    <button mat-raised-button fxFill [color]="'accent'" [disabled]="form.invalid" [ngSwitch]="''">
      <ng-container *ngSwitchDefault>送信する</ng-container>
    </button>
  </form>
</app-section>