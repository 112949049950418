<app-section [color]="'white'">
  <h3 class="bold-heading">
    COMPANY
  </h3>
  <div class="information">
    <img src="assets/images/luster/&athlogo.jpg" width="20%" height="20%" />
    <div>株式会社& ath</div>
    <div>〒102-0093<br></div>
    <div>東京都千代田区平河町1丁目6-4 H1O平河町805</div>
    <p>
    <div>代表取締役　三保 将司</div>
    <p>
    <div>2020年4月 設立</div>
    <div>info@and-ath.com</div>
    <!-- <dl>
        <dt [ngSwitch]="''">
          <ng-container *ngSwitchDefault>代表取締役社長</ng-container>
        </dt>
        <dd [ngSwitch]="''">
          <ng-container *ngSwitchDefault>三保 将司</ng-container>
        </dd>
      </dl> -->
  </div>
</app-section>