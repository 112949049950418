<div class="gradient" fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="8%"></div>
  <div fxFlex="42%" fxFlex.lt-md="42%" fxLayoutGap="12px">
    <p class="heading">稼働率を自分で決め、<br>商流が浅く、<br>コンサルタントに還元される世界へ</p>
    <button mat-flat-button color="white" routerLink="/company/contact">
      案件を探す
    </button>
    <button mat-flat-button color="white" routerLink="/company/contact">
      フリーランスを探す
    </button>
  </div>
  <img fxFlex="50%" fxFlex.lt-md="50%" class="heading-img" src="assets/images/luster/Visual-data-bro.png" />
</div>

<app-section [color]="'white'">
  <h3 class="bold-heading">商流の浅い案件が多数</h3>
  <div fxLayout="row wrap" fxLayoutGap="5px grid" fxLayoutAlign="space-around start">
    <div class="item" fxFlex.xs="100">
      <mat-card-title>PMO</mat-card-title>
      <img mat-card-image src="assets/images/luster/Circles-pana.png" width="100%" height="100%" />
    </div>
    <div class="item" fxFlex.xs="100">
      <mat-card-title>PM</mat-card-title>
      <img mat-card-image src="assets/images/luster/uploading-amico.png" width="100%" height="100%" />
    </div>
    <div class="item" fxFlex.xs="100">
      <mat-card-title>新規事業コンサルタント
      </mat-card-title>
      <img mat-card-image src="assets/images/luster/Uploading-pana.png" width="193" height="130" />
    </div>

  </div>
</app-section>

<app-section [color]="'mat-light-green'">
  <h3 class="bold-heading">Features</h3>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row wrap" fxLayoutGap="5px grid">
      <div class="item" fxFlex.xs="100">
        <mat-card-title>稼働率を自分で決められる</mat-card-title>
        <P>希望稼働率を決めることで、それに見合った案件だけを提案</P>
      </div>
      <div class="item" fxFlex.xs="100">
        <mat-card-title color="primary">徹底した商流制限</mat-card-title>
        <P>徹底的な商流制限をした厳選した案件ばかりを紹介可能</P>
      </div>
      <div class="item" fxFlex.xs="100">
        <mat-card-title>適切な給与を獲得できる</mat-card-title>
        <P>商流制限をしているので、見合った単価を獲得することが可能</P>
      </div>
    </div>

    <img fxFlex.lt-md="30%" class="features-img" src="assets/images/luster/Visual-data-bro.png" />
  </div>
</app-section>

<app-section [color]="'white'">
  <h3 class="bold-heading">Q&A</h3>
  <div>
    <h4>稼働率を決められるとはどういうことですか?</h4>
    <mat-card-content>
      <p>ご自身の希望の稼働率（20%,40%,60%,80%,100%）を決めることで、弊社が希望にあった案件を案件を紹介させて頂きます。</p>
    </mat-card-content>
  </div>
  <div>
    <h4>希望稼働率を途中で変更することはできますか?</h4>
    <mat-card-content>
      <p>弊社とのメールのやり取りの中で、ご希望を仰って頂けると柔軟に対応させて頂きます。</p>
    </mat-card-content>
  </div>
  <div>
    <h4>商流が少ないというのはどういうことか?</h4>
    <mat-card-content>
      <p>従来は、クライアントと求職者との間に会社が多く入っているため、結局求職者に還元されるお金は少なくなっています。New Workerはその現状を踏まえ、商流制限を設け、適切な給与が求職者に還元されるようにします。</p>
    </mat-card-content>
  </div>
  <div>
    <h4>どうしたら案件を探すことができますか?</h4>
    <mat-card-content>
      <p>「案件を探す」→フォーム入力→送信、のあと、弊社からご希望にあった案件情報のメールをお送りさせて頂きます。</p>
    </mat-card-content>
  </div>
  <button mat-button color="black" routerLink="/company/contact">
    案件を探す
  </button>
</app-section>